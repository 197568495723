'use client';
import React, { useState } from 'react';
import { Portal } from '@/lib/constants';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { useTranslations } from 'next-intl';
import NextImage from 'next/image';
import CHCLogo from '@/public/static/CHCLogo.svg';
import { NavigationMenu, DropdownMenu } from 'radix-ui';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { HamburgerMenuIcon, Cross1Icon } from '@radix-ui/react-icons';
import { Flex, Link, Button, Separator } from '@radix-ui/themes';

export default function LayoutHeader({ portal }: { portal: Portal }) {
  const t = useTranslations('LayoutHeader');
  const navItemLabels = ['Kids', 'Causes', 'About', 'Home', 'Parents', 'Story', 'Donate', 'Library'];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const NavLink = ({ href, children, ...props }: { href: string; children: string }) => {
    const pathname = usePathname();
    const isActive = href === pathname;

    return (
      <NavigationMenu.Link asChild active={isActive}>
        <NextLink href={href} {...props}>
          {children}
        </NextLink>
      </NavigationMenu.Link>
    );
  };

  const basicItems = [
    { label: 'Kids', href: '/kidsLogin' },
    { label: 'Causes', href: '/causes' },
    { label: 'About', href: '/about' },
  ];

  const publicItems = [{ label: 'Home', href: '/' }, { label: 'Parents', href: '/parents' }, ...basicItems];
  const parentsItems = [{ label: 'Home', href: '/parents' }, ...basicItems];
  const adminItems = [...parentsItems, { label: 'Admin', href: '/admin' }];

  const kidsItems = [
    { label: 'Home', href: '/kids/home' },
    { label: 'Story', href: '/kids/story' },
    { label: 'Donate', href: '/kids/donate' },
    { label: 'Library', href: '/kids/library' },
    { label: 'About', href: '/about' },
  ];

  const navItems =
    portal == Portal.Public
      ? publicItems
      : portal == Portal.Admin
        ? adminItems
        : portal == Portal.Parents
          ? parentsItems
          : kidsItems;

  return (
    <>
      <NavigationMenu.Root
        orientation="horizontal"
        className="flex flex-row items-center justify-between px-4 mt-3 w-full max-sm:hidden"
      >
        <NavigationMenu.List className="justify-self-start">
          <NavigationMenu.Item>
            <NextLink href="/">
              <NextImage src={CHCLogo} width={60} height={60} alt="Chesed Club Logo" />
            </NextLink>
          </NavigationMenu.Item>
        </NavigationMenu.List>

        <NavigationMenu.List className="justify-self-center flex flex-row gap-4">
          {navItems.map((item, index) => (
            <NavigationMenu.Item key={index} className="font-semibold text-lg">
              <NavLink href={item.href}>{t(item.label)}</NavLink>
            </NavigationMenu.Item>
          ))}
          <LanguageSwitcher />
        </NavigationMenu.List>

        <NavigationMenu.List className="justify-self-end flex flex-row gap-4">
          {portal === Portal.Public ? (
            <>
              <NavigationMenu.Item>
                <Button asChild variant="surface" size="3" radius="small">
                  <NavLink href={'/parents'}>{t('LogIn')}</NavLink>
                </Button>
              </NavigationMenu.Item>
              <NavigationMenu.Item>
                <Button asChild variant="surface" size="3" radius="small">
                  <NavLink href={'/signup'}>{t('SignUp')}</NavLink>
                </Button>
              </NavigationMenu.Item>
            </>
          ) : (
            <NavigationMenu.Item>
              <Button asChild variant="surface" size="3" radius="small">
                <NavLink href={'/logout'}>{t('LogOut')}</NavLink>
              </Button>
            </NavigationMenu.Item>
          )}
        </NavigationMenu.List>
      </NavigationMenu.Root>

      <DropdownMenu.Root modal={true} onOpenChange={onOpenChange}>
        <div className="max-sm:flex px-2 mt-4 hidden items-center justify-between">
          <Flex display="flex" direction="row" align="center" justify="center" className="justify-self-start">
            <DropdownMenu.Trigger asChild>
              <Button type="button" variant="soft">
                {!isOpen && <HamburgerMenuIcon width={24} height={24} color="black" />}
                {isOpen && <Cross1Icon width={24} height={24} color="black" />}
              </Button>
            </DropdownMenu.Trigger>
            <NextLink href={`/`} className="mt-2 ">
              <NextImage src={CHCLogo} width={60} height={60} alt="Chesed Club Logo" />
            </NextLink>
          </Flex>
          {portal == Portal.Public ? (
            <Flex display="flex" direction="row" gap="2" className="justify-self-end">
              <Button asChild variant="surface" size="3" radius="small">
                <Link href={'/parents'}>{t('LogIn')}</Link>
              </Button>
              <Button asChild variant="surface" size="3" radius="small">
                <Link href={'/signup'}>{t('SignUp')}</Link>
              </Button>
            </Flex>
          ) : (
            <Button asChild variant="surface" size="3" radius="small" className="justify-self-end">
              <Link href={'/logout'}>{t('LogOut')}</Link>
            </Button>
          )}
        </div>
        <Separator orientation="horizontal" size="4" mt="4" />
        <DropdownMenu.Portal>
          <>
            <DropdownMenu.Content
              className="relative z-30 mt-4 py-2 h-screen w-screen bg-gray-200 bg-opacity-80 backdrop-blur-md flex flex-col items-center gap-4"
              sideOffset={0}
              loop={true}
            >
              {navItems.map((item, index) =>
                navItemLabels.includes(item.label) ? (
                  <DropdownMenu.Item key={index}>
                    <Link href={item.href} className="font-semibold text-xl m-1">
                      {' '}
                      {t(item.label)}
                    </Link>
                  </DropdownMenu.Item>
                ) : (
                  ''
                )
              )}
              <LanguageSwitcher />
            </DropdownMenu.Content>
            <div className="fixed inset-0 z-20 bg-black opacity-50" />
          </>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}
